export default {
	name: 'login',
	data() {
		return {
			userName: '',
			pwd: "",
			msg: '',
			userMsg: '',
			token: '',
			identity: ''
		}
	},
	created() {
		//			localStorage.setItem('openid',this.$router.currentRoute.query.openid);
		//			localStorage.setItem('isAdmin',this.$router.currentRoute.query.openid);
	},
	methods: {
		//登录
		login: function () {
			var _this = this;
			if (_this.userName == "" || _this.pwd == "") {
				_this.msg = '用户名密码不能为空'
			} else {
				_this.$http.post('API/ALS/checkUser', {
					phone: _this.userName,
					password: _this.pwd
				})
					.then(function (response) {
						if (response.data.code == 200) {
							//							console.log(response.data.data)
							_this.token = response.data.data;
							_this.setCookie('token', _this.token, 7)
							_this.$http.post('/API/ALS/userinfo', {}, {
								headers: { "token": _this.token }
							})
								.then(function (response) {
									if (response.data.code == 200) {
										//									console.log(response.data.data.type)
										_this.identity = response.data.data.type
										_this.setCookie('type', _this.identity, 7);
										_this.$router.push('/platformStatistics')
									}
								})
						} else if (response.data.code == 204) {
							_this.msg = response.data.msg
						}
					})
					.catch(function (error) {
						console.log('接口异常');
					});
			}
		}
	}
}
