import { render, staticRenderFns } from "./login2.vue?vue&type=template&id=45ac3a1e&scoped=true&"
import script from "./login2.js?vue&type=script&lang=js&"
export * from "./login2.js?vue&type=script&lang=js&"
import style0 from "./login2.less?vue&type=style&index=0&id=45ac3a1e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45ac3a1e",
  null
  
)

export default component.exports